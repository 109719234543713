/**
 *
 * Custom function
 *
 */

(function($) {

	/*
	 *	Funzione per rendere un box tutto cliccabile. ATTENZIONE: all'interno del box deve esserci un unico link
	 */


	function allClick() {
		var clickbox = $('.allclick');
		clickbox.each(function() {
			var $this = $(this);
			$this.css('cursor', 'pointer');
			$this.find('a').click(function(event) {
				event.preventDefault();
			});

			if ($this.find('a').attr('target') == '_blank') {
				$this.click(function() {
					window.open($('a', this).attr('href'));
				});
			} else {
				$this.click(function() {
					window.location.href = $('a', this).attr('href');
				});
			}
		});
		
	}

	/*
	 *	Caricamento ajax delle immagini dal marcatore [data-loadimg] e dei background dal marcatore [data-loadbackground]
	 */

	function loadAsynchronousImage() {

		$('*[data-loadimg]').each(function() {
			var box = $(this),
				src = box.attr('data-loadimg'),
				alt = '',
				img = new Image();

			if (box.attr('data-alt')) {
				alt = box.attr('data-alt');
			}

			$(img).load(function() {
				box.append('<img src="' + src + '" alt="' + alt + '" width="' + img.width + '" height="' + img.height + '">');
				$('img', box).hide().fadeIn();

			}).attr('src', src);
		});

		$('*[data-loadbackground]').each(function() {
			var box = $(this),
				src = box.attr('data-loadbackground');

			box.css({
				'background-image': 'url(' + src + ')',
				'background-repeat': 'no-repeat',
				'background-position': 'top center'
			});

			if (box.has(['data-position'])) {
				box.css({
					'background-position': box.attr('data-position'),
					'background-image': 'url(' + src + ')',
					'background-repeat': 'no-repeat'
				});
			} else {
				box.css({
					'background-position': 'top center',
					'background-image': 'url(' + src + ')',
					'background-repeat': 'no-repeat'
				});
			}

			if (box.attr('data-repeat') == 'repeat') {
				box.css({
					'background-repeat': 'repeat'
				});
			} else {
				box.css({
					'background-repeat': 'no-repeat'
				});
			}
		});
	}


	/*
	 *	Funzione per la gestione dei bottoni "condividi" legati ai social
	 */

	function socialOpen() {
		$('[data-socialurl]').each(function() {
			var $this = $(this),
				url = $this.attr('data-socialurl');

			$this.click(function() {
				window.open(url, '', 'width=500, height=500');
			});

		});
	}

	function trackAdwordsCampaign() {
		//$('body').append('<img height="1" width="1" style="border-style:none;" alt="" src=""/>');
	}


	/*
	 *	Funzione per l'apertura di una finestra modale tramite cookie
	 */

	function openModal() {
		if($('#onloadmodal').length) {
			if (!$.cookie("openmodal")) {
				$('#onloadmodal').modal();
				$.cookie("openmodal", 'no', {
					path: '/'
				});
			}
		}
	}



	/*
	 *	Funzione per l'apertura di un iframe all'interno di una finestra modale
	 */

	function iframeModalOpen() {
		$('.videoModal').each(function() {
			// impostiamo gli attributi da aggiungere all'iframe es: data-src andrà ad impostare l'url dell'iframe
			$('.modalButton').on('click', function(e) {
				var src = $(this).attr('data-src');
				var width = $(this).attr('data-width') || 640; // larghezza dell'iframe se non impostato usa 640
				var height = $(this).attr('data-height') || 360; // altezza dell'iframe se non impostato usa 360

				var allowfullscreen = $(this).attr('data-video-fullscreen'); // impostiamo sul bottone l'attributo allowfullscreen se è un video per permettere di passare alla modalità tutto schermo

				// stampiamo i nostri dati nell'iframe
				$(".videoModal iframe").attr({
					'src': src,
					'height': height,
					'width': width,
					'allowfullscreen': ''
				});
			});

			// se si chiude la modale resettiamo i dati dell'iframe per impedire ad un video di continuare a riprodursi anche quando la modale è chiusa
			$(this).on('hidden.bs.modal', function() {
				$(this).find('iframe').html("");
				$(this).find('iframe').attr("src", "");
			});
		});
	}

	/*
	 *	Funzione per la formattazione delle tabelle con classe listino
	 *	tramite le classi di bootstrap
	 */

	function responsiveTable() {

		$('.entry-content table').each(function() {
			var $this = $(this);
			$this.addClass('table');
			$this.wrap('<div class="table-responsive"></div>');
		});

		if ($(window).width() <= 767) {

			$('#primary .table-responsive').prepend(
				'<span class="angle-scroll-left">' +
				'<i class="icon icon-edt-arrow-right-6 angle-scroll-left__animation"></i>' +
				'</span>'
			);

			$('#primary .table-responsive').on('scroll', function(event) {
				var angleScrollLeft = $('.angle-scroll-left');

				if ($(this).scrollLeft() >= 1) {
					angleScrollLeft.addClass('fade-out');
				} else {
					angleScrollLeft.removeClass('fade-out');
				}
			});

			$('#primary').find('.table-responsive').each(function() {
				var table = $(this);
				$('.angle-scroll-left').click(function(event) {
					table.animate({
						scrollLeft: '+=60'
					}, 200);
				});
			});
		}
	}

	/*
	 *	Funzione per l'inizializzazione della mappa
	 */
	function initializeGmap() {
		$('*[data-location]').each(function() {
			var map = $(this),
				address = map.attr('data-location');
			map.setGMap({
				address: address
			});
		});
	}


	/**
	 * Funzione per l'attivazione del menu responsive
	 */

	function responsiveMenu() {
		$('.main-navigation').find('a[href="#"]').each(function() {
			$(this).click(function(event) {
				event.preventDefault();
			});
		});

		var menuPrimaryOffcanvas = $("#js-menu-primary-offcanvas");
			
		$('.main-navigation').find('a[href="#"]').each(function() {
			$(this).click(function(event) {
				$('ul.sub-menu').removeClass('open');
				$(this).next().toggleClass('open');
			});
		});

		$(".menu-offcanvas-button-open").click(function() {
			menuPrimaryOffcanvas.addClass("offcanvas-open");
		});

		$(".menu-offcanvas-button-close").click(function() {
			menuPrimaryOffcanvas.removeClass("offcanvas-open");
		});
	}


	/*
	 * Funzione per attivare e gestire il plugin Full Page
	 */

	function fullPageScroll(selector) {
		var main = $(selector);

		main.fullpage({
			// Navigation
			navigation: true,
			navigationPosition: 'right',
			lockAnchors: true,

			// Scrolling
			css3: true,
			scrollingSpeed: 1000,
			loopHorizontal: false,
			scrollOverflow: true,

			// Accessibility
			//recordHistory: false,

			// Events
			afterRender: function() {
				// Alla sezione attiva faccio corrispondere la relativa voce di menu
				var sectionName = $('.section.active').data('anchor');
				var menuSection = $('.menu').find('a[href$="#' + sectionName + '"]');
				if (menuSection.length > 0) {
					menuSection.closest('li').addClass('current-menu-item').siblings().removeClass('current-menu-item');
				}

				// Nascondo la barra di navigazione per dispositivi mobili
				if ( $(window).width() < 992 ) {
					$('#fp-nav').hide();
				}
				
				// Per evitare eventuali conflitti con altri plugin li richiamiamo qui
				// owlCarousel
				startOwl('.owl-carousel');

				// equalHeights
				if ( $(window).width() > 767 ) {
					$('.offer-list .offer-item .data').equalHeights();
				}
			},

			afterLoad: function(anchorLink, index) {
				// Ripristino url se link con cancelletto
				if (window.location.hash) {
					var arrUrl = window.location.href.replace("http://", "").replace("https://", "").split("/");
					var arrPageUrl = ( lang == 'it' ) ? arrUrl.splice(0, 1) : arrUrl.splice(0, 2); // arrUrl.splice(0, 2) per dev.editatest.com

					if ( location.protocol == 'http:' ) {
						history.pushState(null, null, "http://" + arrPageUrl.join("/") + "/");
					} else {
						history.pushState(null, null, "https://" + arrPageUrl.join("/") + "/");
					}
				}

				// Alla sezione attiva faccio corrispondere la relativa voce di menu
				var menuSection = $('.menu').find('a[href$="#' + anchorLink + '"]');
				if (menuSection.length > 0) {
					menuSection.closest('li').addClass('current-menu-item').siblings().removeClass('current-menu-item');
				}
			},

			onLeave: function(index, nextIndex, direction) {
				if ( nextIndex > 1 ) {
					$('.fp-controlArrow.fp-next').hide();
				}
			},

			afterSlideLoad: function(anchorLink, index, slideAnchor, slideIndex) {
				var pageUrl = $('.section.active').find('.slide.active').data('url');

				// Se sono all'indice 0 non aggiungo il nome della slide ma imposto il base url del sito
				if ( slideIndex > 0 ) {
					// Inibisco lo scorrimento verticale da mouse e tastiera
					$.fn.fullpage.setAllowScrolling(false, 'up, down');
					$.fn.fullpage.setKeyboardScrolling(false, 'up, down');
					
					// Nascondo i pallini di navigazione
					$('#fp-nav').hide();

					// Se sono nella prima slide della sezione camere nascondo le frecce di navigazione
					if (( anchorLink == 'camere' || anchorLink == 'rooms' || anchorLink == 'apartments' ) && slideIndex == 1 ) {
						$('.fp-controlArrow').hide();
					}

					// Nascondo il pulsante per andare verso il basso
					$('.moveDown').hide();

					// In corrispondenza del bottone del browser ("back") si cambia la slide
					$(window).on("popstate", function(e) {
						$.fn.fullpage.moveSlideLeft();
					});
				} else {
					// Ripristino
					if ( $(window).width() > 991 ) $('#fp-nav').show();
					if ( $(window).width() > 767 ) $('.moveDown').show();
					$.fn.fullpage.setAllowScrolling(true);
					$.fn.fullpage.setKeyboardScrolling(true);
					
					$('.fp-controlArrow.fp-next').hide();

					pageUrl = ( lang == 'it' ) ? site_url : site_url + '/' + lang + '/';
				}

				history.pushState(null, null, pageUrl);
			},

			onSlideLeave: function(anchorLink, index, slideIndex, direction, nextSlideIndex) {
				if ( nextSlideIndex > 0 ) {
					$('#js-header').addClass('inverse');
				} else {
					$('#js-header').removeClass('inverse');
				}
			}

		});
	}


	/*
	 *  Funzioni per PhotoSwiper
	 */
	function addImageSizeForPhotoSwiper() {
		var thumbs = $('img[data-big-size]');
		thumbs.each(function(index) {
			if ($(this).parent('a').length > 0) {
				var isImageLink = $(this).parent('a').attr('href').match(/\.(jpeg|jpg|gif|png)$/);
				if (isImageLink) {
					big_size = $(this).data('big-size');
					link = $(this).parent('a');
					link.attr('data-size', big_size);
					link.wrap('<div class="single-image"></div>');
				}
			}
		});
	}

	function initPhotoSwiper(selector) {
		var galleryItems = $(selector);
		var pswpItems = [];
		galleryItems.each(function(index) {

			if($(this).find('a').attr('data-video')){
				myImage = {
					html: $(this).find('a').attr('data-video')
				};
			}else{
				var dimensions = $(this).find('a').data('size').split('x');
				var width = parseInt(dimensions[0], 10);
				var height = parseInt(dimensions[1], 10);
				myImage = {
					src: $(this).find('a').attr('href'),
					w: width,
					h: height,
					msrc: $(this).find('img').attr('src')
				};
			}


			pswpItems.push(myImage);
		});


		galleryItems.each(function(index) {
			$(this).click(function(evt) {
				evt.preventDefault();
				openPhotoSwipe(pswpItems, index);
			});
		});
	}

	function openPhotoSwipe(galleryItems, index) {
		var options = {
			index: index
		};
		var pswpElement = $('.pswp')[0];

		var gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, galleryItems, options);
		gallery.init();
	}


	function smoothScroll() {
		$("a[href*='#']").not("[href='#'],[data-toggle],[target='_blank']").click(function() {
			if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
				var target = $(this.hash);
				target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
				if (target.length) {

					if(target.attr('data-paddingTopScroll')) {
						var adjust = target.attr('data-paddingTopScroll');
					}
					else {
						var adjust = target.css('padding-top') ? target.css('padding-top').replace('px', '') : 0;
					}

					$('html, body').animate({
						scrollTop: target.offset().top - adjust
					}, 1000);

					return false;
				}
			}
		});
	}

	function startOwl(selector) {
		$(selector).each(function(arguments) {
			var owl = $(this);
			var params = {
				nav : owl.data('owl-navigation'),
				navSpeed : owl.data('owl-slide-speed'),
				autoplay : owl.data('owl-autoplay'),
				autoplayTimeout : owl.data('owl-autoplay-timeout'),
				autoplaySpeed : owl.data('owl-autoplay-speed'),
				dots : owl.data('owl-dots'),
				dotsSpeed : owl.data('owl-dots-speed'),
				loop : owl.data('owl-loop'),
				autoHeight : false,
				lazyLoad : true,
				navText : [
					"<i class='icon-edt-arrow-left-2'></i>",
					"<i class='icon-edt-arrow-right-2'></i>"
				]
			};

			if ( owl.data('owl-nav-prev') && owl.data('owl-nav-next') ) {
				params['navText'] = [
					owl.data('owl-nav-prev'),
					owl.data('owl-nav-next')
				];
			}

			if(owl.data('owl-single-item') == true) {
				params['items'] = 1;
			} else {
				params['responsive'] = {
					0: {
						items: owl.data('owl-items-xs'),
					},
					768: {
						items: owl.data('owl-items-sm'),
					},
					992: {
						items: owl.data('owl-items-md'),
					},
					1200: {
						items: owl.data('owl-items-lg'),
					},
					1440: {
						items: owl.data('owl-items-xl'),
					}
				};
			}

			owl.owlCarousel(params);

			if(owl.data('owl-prev')){
				$(owl.data('owl-prev')).click(function(){
					owl.trigger('prev.owl.carousel');
				});
			}
			if(owl.data('owl-next')){
				$(owl.data('owl-next')).click(function(){
					owl.trigger('next.owl.carousel');
				});
			}
		});
	}

	/*****************************************************************************************/

	function toConsole(string) {
		if ((typeof window.console == "undefined")) {
			alert(string);
		} else console.log(string);
	}

	function $j(argument) {
		var result = jQuery(argument);
		if (result.length === 0) return null;
		else return result;
	}

	function translateCountdown( langCountdown ) {

		countdown.resetLabels();

		if ( langCountdown == 'it' ) {
			countdown.setLabels(
				' millisecondo| secondo| minuto| ora| giorno| settimana| mese| anno| decade| secolo| millennio',
				' millisecondi| secondi| minuti| ore| giorni| settimane| mesi| anni| decadi| secoli| millenni',
				' e ',
				' '
			);
		} else if ( langCountdown == 'de' ) {
			countdown.setLabels(
				' Millisekunde| zweite| Minute| jetzt| Tag| Woche| Monat| Jahr| Jahrzehnt| Jahrhundert| Jahrtausend',
				' Millisekunden| Sekunden| Minuten| Stunden| Tage| Wochen| Monate| Alter| Jahrzehnte| Jahrhunderte| Jahrtausende',
				' und ',
				' '
			);
		} else if ( langCountdown == 'fr' ) {
			countdown.setLabels(
				' milliseconde| deuxième| minute| maintenant| jour| semaine| mois| année| décennie| siècle| millénaire',
				' millisecondes| secondes| minutes| heures| journées| semaines| mois| âge| décennies| siècles| millénaires',
				' et ',
				' '
			);
		}

	}

	function stampaCountdown( item, response ){

		jQuery( item ).find( '.pageTimer' ).empty().append(
			moment().countdown(response, countdown.DAYS|countdown.HOURS).toString()
		);
	}

	function closePhotoswipe() {

		$('.pswp__button--close').click(function() {
			if($('.pswp').hasClass('pswp--open')) {
				$('.pswp').removeClass('pswp--supports-fs pswp--open pswp--notouch pswp--css_animation pswp--svg pswp--animated-in pswp--visible pswp--zoom-allowed pswp--has_mouse');
			}
		});

		$(window).scroll(function() {
			if($('.pswp').hasClass('pswp--open')) {
				$('.pswp').removeClass('pswp--supports-fs pswp--open pswp--notouch pswp--css_animation pswp--svg pswp--animated-in pswp--visible pswp--zoom-allowed pswp--has_mouse');
			}
		});
	}


	/*
	 * Funzione per utilizzare MixItUp da select
	 */

	function filterElements() {

		var filterSelect = '.select-items',
			container = '.mix-filter-support';

		if ( $(container).length ) {
			var mixer = mixitup(container);

			$(filterSelect).on('change', function(){
				mixer.filter(this.value);
			});
		}
	}


	/**
	 * Questo oggetto ci serve a verificare il dispositivo utilizzato dall'utente,
	 * la i. nell'espressione regolare sta ad indicare una ricerca case-insensitive,
	 * il metodo nativo test() serve a verificare la presenza della parola nell'espressione regolare,
	 * ritorna un boleano.
	 */
	var mapsMobileUrl = {
		isAndroid: function() {
			return /(android)/i.test(navigator.userAgent);
		},
		isIos: function() {
			return /(iPhone|iPad|iPod)/i.test(navigator.userAgent);
		}
	};

	function injectorLinkMaps()
    {
        var buttonMaps = $('#js-menu-primary-offcanvas,.button-mappa').find('.dovesiamo a');

        if ( mapsMobileUrl.isAndroid() ) buttonMaps.attr( 'href', phpMapsMobileUrl.android );

		if ( mapsMobileUrl.isIos() ) buttonMaps.attr( 'href', phpMapsMobileUrl.ios );
		
		if ( mapsMobileUrl.isIos() || mapsMobileUrl.isAndroid() ) buttonMaps.attr( 'target', '_blank' );

        // if ( ! mapsMobileUrl.isAndroid() && ! mapsMobileUrl.isIos() ) buttonMaps.hide();
    }

	
	$j(document).ready(function() {

		allClick();
		responsiveTable();
		addImageSizeForPhotoSwiper();
		iframeModalOpen();
		responsiveMenu();
		filterElements();

		injectorLinkMaps();

		smoothScroll();
		

		closePhotoswipe();

		$('.feedback-description').collapser({
			mode: 'chars',
			truncate: 360,
			showText: '[ + ]',
			hideText: '[ - ]',
			controlBtn: 'opener',
		});

		if ( $('body').hasClass('single') ) startOwl('.owl-carousel');
		if ( $('body').hasClass('tax-camere-type') ) startOwl('.owl-carousel');

		/* FULLPAGE - BEGIN */

		fullPageScroll('#fullpage');

		// Gestione immagini a tutta pagina (sezioni e singola offerta)
		$('.section[data-background], .left-side[data-background]').each(function() {
			var item = $(this);
			item.css({
				'background-image': 'url("' + item.data('background') + '")',
				'background-repeat': 'no-repeat',
				'background-position': 'center center',
				'background-size': 'cover'
			});
		});

		// Spostamento verso la sezione in basso
		$('.moveDown').on('click', function(e) {
			$.fn.fullpage.moveSectionDown();
			e.preventDefault();
		});

		// Entrata alla prima slide (con caricamento Ajax dei contenuti)
		$('body').on('click', '.to-slide', function(e) {
			var hasSlides = $('.section.active .slide').length;

			if ( hasSlides > 1 ) {
				$.fn.fullpage.moveSlideRight();
			} else {
				var loader = $('#loader');
				var slides = $(this).data('slides');
				var firstSlide = $(this).parents('.slide').first();
				var type = $(this).data('type');
				var parent = $(this).data('parent');
				var language = $(this).data('lang');
				var activeSectionIndex = $('.section.active').index();
				var activeSlideIndex = $('.section.active').find('.slide.active').index();

				$.ajax({
					url : site_url + '/wp-content/themes/edita/inc/inc-slides.php',
					type : "POST",
					data : {
						slides : slides,
						type : type,
						parent : parent,
						lang : language
					},
					beforeSend: function() {
						loader.show();
					},
					success : function( response ) {
						firstSlide.nextAll().remove();
						firstSlide.after(response);
						
						$.fn.fullpage.destroy('all');
						
						$('.section').eq(activeSectionIndex).addClass('active');
						if ( activeSlideIndex > -1 ) {
							$('.section.active').find('.slide').eq(activeSlideIndex).addClass('active');
						}

						fullPageScroll('#fullpage');
	
						$.fn.fullpage.moveSlideRight();
					},
					error : function() {
						alert('Errore nella chiamata Ajax!');
					},
					complete : function(data) {
						loader.hide();
					}
				});
			}
			
			e.preventDefault();
		});

		// Ritorno alla sezione
		$('body').on('click', '.slide-back', function(e) {
			var section = $(this).parents('.section').data('anchor');

			// Se slide delle camere, il back porta al riepilogo camere
			if ( $(this).parents('.slide').hasClass('other') ) {
				$.fn.fullpage.silentMoveTo(section, 1);
			} else {
				$.fn.fullpage.moveTo(section, 0);
			}

			e.preventDefault();
		});

		// Gestione link alle slide
		$('body').on('click', '.slide-link', function(e) {
			var target = $(this).data('target').split(','),
				section = target[0],
				slide = target[1];
			
			// questa riga apre il dettaglio della singola camera
			$.fn.fullpage.silentMoveTo(section, slide);
			
			e.preventDefault();
		});

		// Gestione link voci menu (interno vs esterno)
		$('.menu-item').on('click', function(e) {
			var target = $(this).find('a[href^="#"]').attr('href');
			var arrUrl = window.location.href.replace("http://", "").replace("https://", "").split("/"),
				arrPageUrl = ( lang == 'it' ) ? arrUrl.splice(0, 1) : arrUrl.splice(0, 2),
				origUrl = ( location.protocol == 'http:' ) ? "http://" + arrPageUrl.join("/") + "/" : "https://" + arrPageUrl.join("/") + "/";

			if ( !$(this).hasClass('menu-item-static') ) {
				
				if ( target ) {				
					if ( $('body').hasClass('home') ) {
						// Ripristino completo
						if ( $(window).width() > 991 ) $('#fp-nav').show();
						if ( $(window).width() > 767 ) $('.moveDown').show();
						$.fn.fullpage.setAllowScrolling(true);
						$.fn.fullpage.setKeyboardScrolling(true);
						$('#js-header').removeClass('inverse');

						// Trigger sul bottone di ritorno
						if ( $('.section.active').data('anchor') == 'camere' || $('.section.active').data('anchor') == 'rooms' || $('.section.active').data('anchor') == 'apartments' ) {
							$('.section.active .slide-back').first().trigger('click');
						} else {
							$('.slide.active').find('.slide-back').trigger('click');
						}

						// Pulizia history
						history.pushState(null, null, origUrl);

						// Chiusura menu
						$("#js-menu-primary-offcanvas").removeClass("offcanvas-open");

						target = target.replace("#", "");
						if ( $(this).hasClass('to-room') ) {
							$.fn.fullpage.moveTo(target, 1);
						} else {
							$.fn.fullpage.moveTo(target);
						}
					} else {
						target = origUrl + target;
						location.replace(target);
					}

					e.preventDefault();
				}
				
			}
			
		});

		/* FULLPAGE - END */

		if ( typeof lang && lang != 'en' ) {
			translateCountdown( lang );
		}

		$('.gform_wrapper form').each(function() {
			$(this).checkEditaForm();
		});

		$('.checkNewsletter').each(function() {
			$(this).checkEditaForm();
		});

	});


	$j(window).load(function() {
		/*
		 *	Attraverso il Google Loader carico il modulo mappa
		 */
		$.getScript('https://www.google.com/jsapi', function() {
			/* if (url.key) {
				var map_params = 'language=' + url.lingua + '&key=' + url.key;
			} else {
				var map_params = 'language=' + url.lingua;
			} */

			var map_params = 'language=it&key=AIzaSyCqk5ACEgZYoPwOa5aRAMWRmdbZTu3IwNc&libraries=places';

			google.load('maps', '3', {
				other_params: map_params,
				callback: function() {
					initializeGmap();
				}
			});
		});

		// Inizializzazione PhotoSwiper
		var gallery = $('.gallery');
		if ( gallery.length ) {
			gallery.each(function( index ) {
				initPhotoSwiper( "#" + $( this ).attr('id') + " .gallery-item");
			});
		}
		initPhotoSwiper(".entry-content .single-image");

	});

	$j(window).resize(function() {
		


	});

})(jQuery);
