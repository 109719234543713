/*
 *		setGmap v 3
 *
 *		Descrizione
 *			Gestisce la creazione della mappa interattiva di Google, il calcolo dell'itinerario dalla posizione dell'utente e l'autocompletamento.
 *		
 *		Parametri da indicare sul div della mappa
 *			data-zoom: indica lo zoom da 0 a 19
 *			data-location: indica la posizione della struttura tramite indirizzo o coordinate (lat, lng)
 *			data-message: indica il testo da far comparire nell'infowindow. Di base c'è sempre il nome del sito
 *
 */

(function($){
	$.fn.setGMap= function(settings){
		
		var markers = []; // variabile globale per il marker dell'autocompletamento
		var $this = $(this);
	
		settings = jQuery.extend({
			ID:				$this.attr('id'), // id per aggancio mappa
			zoom:			parseInt($this.attr('data-zoom')), // default 15
			address:		$this.attr('data-location'), // località impostata nel backend "lat, lng"
			message:		$this.attr('data-message'), // TRUE: usa infowindow
			route_stamp_ID:	'map_route', // id per stampare il percorso della mappa
			inputsearch:	'map_set_position' // id dell'input per la ricerca con completamento automatico
		}, settings);

		var getLocation = function(address) { // questa funzione trasforma un'indirizzo in questa forma "lat, lng" in un oggetto che le gmap api possono interpretare 
			return {
				lat: parseFloat(address.split(', ')[0]),
				lng: parseFloat(address.split(', ')[1])
			}
		}

		function initMap() {
			var directionsDisplay = new google.maps.DirectionsRenderer;
			var directionsService = new google.maps.DirectionsService;        

			var map = new google.maps.Map(document.getElementById( settings.ID ), {
				zoom: settings.zoom,
				center: getLocation(settings.address),
			});

			//rimuove gli hotel (tutti i punti collegati al business) dalla mappa
			map.set('styles', [{
				featureType: "poi.business",
				elementType: "labels",
				stylers: [{ visibility: "off" }]
			}]);

			directionsDisplay.setMap(map);
			directionsDisplay.setPanel(document.getElementById(settings.route_stamp_ID));

			//settaggio marker
			marker = new google.maps.Marker({
				position: getLocation(settings.address),
				map: map,
				draggable: false
			});

			// aggiungi marker con infowindow
			addInfo(map, marker, settings.message, getLocation(settings.address), true);

			// aggancio la funzione per la ricerca con autocomplete
			var searchBox = new google.maps.places.SearchBox(document.getElementById(settings.inputsearch));

			map.addListener('bounds_changed', function() {
				searchBox.setBounds(map.getBounds());
			});
			// al submit del form lancio il calcolo della rotta
			var onChangeHandler = function() {
				calculateAndDisplayRoute(directionsService, directionsDisplay);
			};
			// all'evento places_changed lancio la funzione per la ricerca automatica dei luoghi ed il settaggio del marker
			var onChangePlaces = function() {
				initAutocompleteSearch(map, searchBox);
			};
			
			$( "#gmap_init" ).submit(function( event ) {
				event.preventDefault();
				
				onChangeHandler();
			});

			searchBox.addListener('places_changed', onChangePlaces);
		}

		function calculateAndDisplayRoute(directionsService, directionsDisplay) {
			var start = getLocation(settings.address);
			var end = document.getElementById(settings.inputsearch).value;

			directionsService.route({
				origin: end,
				destination: start,
				travelMode: 'DRIVING'

			}, function(response, status) {
				if (status === 'OK') {
					directionsDisplay.setDirections(response);

				} else {
					console.log('Directions request failed due to ' + status);
				}
			});
		}

		function addInfo(map, marker, message, position, open){ // funzione per l'aggiunta del marker
			var infowindow = new google.maps.InfoWindow({
				content: message,
				position: position
			});

			// se open sta a true allora il marker parte già aperto
			if(open) infowindow.open(map, marker);

			google.maps.event.addListener(marker, 'click', function() {
				infowindow.open(map,marker);
			});
		}

		function initAutocompleteSearch(map, searchBox) {

			var places = searchBox.getPlaces();

			if (places.length == 0) return;

			// Cancelliamo tutti i precedenti marker sulla mappa, prima di disegnare i nuovi
			markers.forEach(function(marker) {
				marker.setMap(null);
			});

			markers = [];

			// For each place, get the icon, name and location.
			var bounds = new google.maps.LatLngBounds();

			places.forEach(function(place) {
				if (!place.geometry) {
					console.log("Returned place contains no geometry");
					return;
				}

				// Create a marker for each place.
				markers.push(new google.maps.Marker({
					map: map,
					title: place.name,
					position: place.geometry.location
				}));

				if (place.geometry.viewport) {
					bounds.union(place.geometry.viewport);
				} else {
					bounds.extend(place.geometry.location);
				}
			});

			map.fitBounds(bounds);
		}
		
		initMap();

		return this;
	}
})(jQuery);